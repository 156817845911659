import React from "react";
import GContainer from "../components/GContainer";
import GLink from "../components/GLink";
import MatchPoints from "../components/MatchPoints";
import { Typography, Grid, Hidden, Divider, ButtonBase, Link } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import imDataManNextL from "../images/datamanNextL.png";
import imDataManProM from "../images/datamanProM.png";
import imDataManEnM from "../images/datamanEnM.png";

//last
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  heroBox: {
    backgroundColor: "#fafafa",
    backgroundImage: "linear-gradient(to bottom, #fafafa 0%, #fff 50%)"
  },

  getButton: {
    backgroundColor: "#0071e3",
    color: theme.palette.common.white,
    padding: "0px 52px",
    height: 68,
    borderRadius: 34,
    "&:hover": {
      opacity: "0.8"
    }
  }
}));

function MediaItem(props) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant={MatchPoints({ xs: "h4", sm: "h5" })} paragraph>
        {props.title}
      </Typography>
      <Typography variant="body1" paragraph>
        {props.children}
      </Typography>
      <GLink newTab url={props.url}>
        Read more
      </GLink>
      {!props.noDivider && (
        <Hidden smUp>
          <Box py={4}>
            <Divider light />
          </Box>
        </Hidden>
      )}
    </Grid>
  );
}

export default function App() {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const iconWidth = 196;
  const iconMargin = "95px";

  return (
    <Box style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>DataMan for iOS - XVision</title>
      </Helmet>

      <Box pt="4rem">
        <GContainer maxW="md">
          <Box pb="4rem">
            <Typography variant="h1" align="center">
              DataMan
            </Typography>
          </Box>
          <Box pb="5rem">
            <Typography variant="h5" align="center">
              <Box lineHeight={1.5}>
                The simplest and clearest app to track your data usage on iPhone and Apple Watch. In
                just a glance, you can know if you&apos;re safe from busting your data cap. With
                real-time statistics and smart forecasts, you don&apos;t have to worry about overage
                again.
              </Box>
            </Typography>
          </Box>

          <Box textAlign="center">
            <ButtonBase
              className={classes.getButton}
              disableRipple
              disableTouchRipple
              component={Link}
              noWrap
              underline="none"
              variant="h6"
              href="https://apps.apple.com/us/app/dataman/id592962356"
            >
              GET THE APP
            </ButtonBase>
          </Box>
        </GContainer>
      </Box>

      <Box pb="8rem" pt="8rem">
        <Box display="flex" width={1} alignItems="flex-start">
          <Box flexGrow={1} mr={iconMargin} overflow="hidden">
            <img src={imDataManEnM} width={iconWidth} style={{ float: "right" }} />
          </Box>
          <Box flexGrow={1} ml={iconMargin} overflow="hidden">
            <img src={imDataManProM} width={iconWidth} />
          </Box>
        </Box>

        <Box textAlign="center" mt="-260px">
          <img src={imDataManNextL} width={234} />
        </Box>
      </Box>

      <Box pb="6rem" className={classes.heroBox}>
        <GContainer>
          <Box pb="8rem" pt="5rem">
            <Typography variant="h2" align="center">
              Media Reviews
            </Typography>
          </Box>

          <Grid container spacing={isSM ? 8 : 10}>
            <MediaItem
              title="Macworld"
              url="http://www.macworld.com/article/3125668/ios/how-to-get-started-with-widgets-in-ios-10.html"
            >
              A life-saver for users who don’t want to have to pay for data overages. Top 10
              must-have Apple Watch app.
            </MediaItem>

            <MediaItem
              title="Business Insider"
              url="http://www.businessinsider.com/the-10-best-iphone-widgets-2015-2"
            >
              DataMan&apos;s widget makes checking your usage lightning-fast. Top 10 best widget you
              should be using.
            </MediaItem>

            <MediaItem
              title="BGR"
              url="http://bgr.com/2014/04/01/dataman-next-free-download-iphone/"
            >
              Download this great app right now. DataMan is as gorgeous as it is useful.
            </MediaItem>

            <MediaItem
              title="The New York Times"
              url="http://www.nytimes.com/2013/12/19/technology/personaltech/data-cops-guard-against-excessive-charges.html"
            >
              The simplest and clearest app to monitor data use and alert you when you&apos;ve used
              too much.
            </MediaItem>

            <MediaItem
              title="AppAdvice"
              url="http://appadvice.com/post/stop-wasting-cellular-data-on-iphone-with-these-tips/700137"
            >
              The best and easiest way to keep an eye on data usage on iOS. It&apos;s well worth the
              download.
            </MediaItem>

            <MediaItem
              title="Cult of Mac"
              url="http://www.cultofmac.com/320162/best-apple-watch-apps-you-may-not-have-heard-of/"
            >
              Never worry about your data limit again. Best Apple Watch app to trick out your wrist.
            </MediaItem>

            <MediaItem
              title="TIME"
              url="http://time.com/33302/heres-whats-draining-your-iphone-battery-and-what-you-can-do-about-it/"
            >
              If you&apos;re looking for an app to help you stay inside your data limits, we like
              the easy-to-use DataMan.
            </MediaItem>

            <MediaItem
              title="CNET"
              url="http://www.cnet.com/news/dataman-next-tracks-your-data-usage-adds-ios-8-widget/"
            >
              See at-a-glance if you&apos;re getting close to your monthly limit. Simple but
              essential.
            </MediaItem>

            <MediaItem
              title="iPhoneinCanada"
              url="http://www.iphoneincanada.ca/app-store/dataman-next-free-track-iphone-data-usage/"
            >
              Go-to app for tracking data usage that&apos;s far more accurate than carrier apps.
            </MediaItem>

            <MediaItem noDivider title="ifanr 爱范儿" url="http://www.ifanr.com/app/1059789">
              「DataMan
              中国」是一个非常轻量的流量监测工具，操作方便、设计简约、监控准确。经常爆流量的用户一定用得上。
            </MediaItem>
          </Grid>
        </GContainer>
      </Box>
    </Box>
  );
}
