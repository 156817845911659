
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function MatchPoints(values) {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  const isSM = useMediaQuery(theme.breakpoints.up("sm"));
  const isXS = true;

  const points = ["xl", "lg", "md", "sm", "xs"];
  for (let i = 0; i < points.length; i++) {
    let aPoint = points[i];

    if (values[aPoint] !== undefined) {
      let query = isXS;
      switch (aPoint) {
        case "xl":
          query = isXL;
          break;

        case "lg":
          query = isLG;
          break;

        case "md":
          query = isMD;
          break;

        case "sm":
          query = isSM;
          break;

        default:
          break;
      }

      if (query) {
        return values[aPoint];
      }
    }
  }
  return Object.values(values)[0];
}

export default MatchPoints;