import React from "react";
import { Link } from "@material-ui/core";
import { Link as GastsbyLink } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import imMoreArrow from "../images/chevron_right-24px.svg";

const useStyles = makeStyles(() => ({
  linkChevron: {
    paddingRight: 14,
    backgroundImage: `url(${imMoreArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundSize: 12,
    textAlign: "right"
  }
}));

function GLink(props) {
  const classes = useStyles();
  const { newTab, url, noChevron, children, ...rest } = props;

  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const isInternal = /^\/(?!\/)/.test(url);
  let router;
  if (isInternal) {
    router = { component: GastsbyLink, to: url };
  } else {
    router = { href: url };
  }
  //console.log(router);

  return (
    <Link
      {...router}
      className={noChevron ? null : classes.linkChevron}
      noWrap
      variant="body1"
      color="secondary"
      target={newTab ? "_blank" : null}
      rel={newTab ? "noopener" : null}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default GLink;
